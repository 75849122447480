<template>
  <v-container fluid>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <div ref="table"></div>
  </v-container>
</template>

<script>
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[tabulator]
import { TabulatorFull as Tabulator } from "tabulator-tables"; //import Tabulator library
//EOC
//BOC:[faker]
import { faker } from "@faker-js/faker";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
  }),
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Leads",
      to: { name: "PageServiceDashboard", params: { serviceKey: "lead" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Deals",
      to: { name: "MockupLeadDealBrowse" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    var data = [];
    for (let i = 1; i <= 100; i++) {
      data.push({
        id: i,
        deal: faker.commerce.productName(),
        company: faker.company.name(),
        contact: faker.person.fullName(),
        salesperson: faker.person.fullName(),
        status: ["Arranged demo","Proposed","Follow Up"][Math.floor(Math.random() * 3)],
        amount: Math.floor(Math.random() * 2000).toFixed(2),
      });
    }
    this.tabulator = new Tabulator(this.$refs.table, {
      layout: "fitColumns",
      pagination: true,
      paginationSize: 5,
      data: data, //set initial table data
      columns: [
        { title: "Deal", field: "deal" },
        { title: "Amount", field: "amount" },
        { title: "Company", field: "company" },
        { title: "Contact", field: "contact" },
        { title: "Salesperson", field: "salesperson" },
        { title: "Status", field: "status" },
        {
          title: "Result",
          width: 150,
          formatter: () => {
            return `<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small success"><span class="v-btn__content">Won</span></button>` + `<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small error ml-1"><span class="v-btn__content">Lost</span></button>`;
          },
        },
      ],
    });
  },
  methods: {
    //
  },
};
</script>